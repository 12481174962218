var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "single login" }, [
    _c("div", { staticClass: "background" }, [
      _c(
        "section",
        { staticClass: "form" },
        [
          _c("h2", [_vm._v(_vm._s(_vm.i18n.t("Sign In")))]),
          _vm._v(" "),
          _c("TucowsLogin", {
            on: { submit: _vm.handleSubmit, change: _vm.handleChange },
            scopedSlots: _vm._u([
              {
                key: "link",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "flat-checkbox input-group terms-accepted",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.terms,
                              expression: "terms",
                            },
                          ],
                          attrs: {
                            id: "accepted",
                            "aria-required": "true",
                            "aria-invalid": _vm.deniedTerms,
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.terms)
                              ? _vm._i(_vm.terms, null) > -1
                              : _vm.terms,
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$a = _vm.terms,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 && (_vm.terms = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.terms = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.terms = $$c
                                }
                              },
                              _vm.handleChange,
                            ],
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "accepted" } }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.i18n.t("I accept the Tiered Access")) +
                              "\n\t\t\t\t\t\t\t"
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "terms-link",
                              attrs: {
                                href: "/terms",
                                target: "_self",
                                rel: "noopener",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.i18n.t("Terms of Service"))
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.deniedTerms
                      ? _c("p", { staticClass: "message error" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.i18n.t(
                                  "Please accept the Terms of Service in order to proceed."
                                )
                              ) +
                              "\n\t\t\t\t\t"
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "forgot" },
            [
              _c("router-link", { attrs: { to: "/forgot" } }, [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.i18n.t("Forgot Password")) +
                    "\n\t\t\t\t"
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "information" }, [
      _c("div", { staticClass: "tieredaccess" }, [
        _c("h3", [_vm._v(_vm._s(_vm.tieredaccess.title))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.tieredaccess.description))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "accessgranted" }, [
        _c("h3", [_vm._v(_vm._s(_vm.accessgranted.title))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.accessgranted.description))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }