<template>
	<main class="single login">
		<div class="background">
			<section class="form">
				<h2>{{ i18n.t('Sign In') }}</h2>
				<TucowsLogin
					@submit="handleSubmit"
					@change="handleChange"
				>
					<template v-slot:link>
						<div class="flat-checkbox input-group terms-accepted">
							<input
								id="accepted"
								v-model="terms"
								aria-required="true"
								:aria-invalid="deniedTerms"
								type="checkbox"
								@change="handleChange"
							>
							<label for="accepted">
								{{ i18n.t('I accept the Tiered Access') }}
								<a
									class="terms-link"
									href="/terms"
									target="_self"
									rel="noopener"
								> {{ i18n.t('Terms of Service') }}</a>
							</label>
						</div>
						<p
							v-if="deniedTerms"
							class="message error"
						>
							{{ i18n.t('Please accept the Terms of Service in order to proceed.') }}
						</p>
					</template>
				</TucowsLogin>
				<div class="forgot">
					<router-link to="/forgot">
						{{ i18n.t('Forgot Password') }}
					</router-link>
				</div>
			</section>
		</div>
		<div class="information">
			<div class="tieredaccess">
				<h3>{{ tieredaccess.title }}</h3>
				<p>{{ tieredaccess.description }}</p>
			</div>
			<div class="accessgranted">
				<h3>{{ accessgranted.title }}</h3>
				<p>{{ accessgranted.description }}</p>
			</div>
		</div>
	</main>
</template>

<script>
/**
 * @class Login
 *
 * Login to rdap application
 */
import TucowsLogin from 'tucows-ui-components/src/Login.vue';
import {mapActions, mapState} from 'vuex';
import _get from 'lodash/get';
import _trim from 'lodash/trim';
import i18n from '@/lib/i18n';

export default {
	name: 'Login',
	components: {TucowsLogin},
	/**
	 * @ignore
	 */
	data() {
		return {
			tieredaccess: {
				title: i18n.t('What is Tiered Access?'),
				description: i18n.t(
					'TieredAccess.com allows accredited, authenticated users with a legitimate interest to look up registration data (Whois info) for domains on the Tucows platform. Results may vary depending on the tier that the user can access.'
				)
			},
			accessgranted: {
				title: i18n.t('How is access granted?'),
				description: i18n.t(
					'Tucows will ensure that only those with legitimate purposes, including law enforcement, intellectual property, and commercial litigation interests will have access to domain registrant information.'
				)
			},
			deniedTerms: false,
			terms: false
		};
	},
	computed: {
		...mapState({errors: (state) => [_get(state, 'note.errors'), _get(state, 'user.errors')].filter(Boolean)}
		),
		/**
		 * Whether there are api errors
		 * @return {boolean}  has errors
		 */
		hasErrors: function() {
			return Boolean(this.errors.length);
		}
	},
	methods: {
		...mapActions({
			'login': 'user/login',
			'createNote': 'note/create',
			'getUserByName': 'user/getUserByName'
		}),
		/**
		 * Form submission includes three steps
		 * 1. Valid login with username and password
		 * 2. Log user has accepted terms
		 * 3. Get user information
		 *
		 * Then proceed to let user into authenticated routes
		 * @param {object}  data    Form data
		 */
		async handleSubmit({data}) {
			this.deniedTerms = !this.terms;

			if (!data || this.deniedTerms) {
				return;
			}

			await this.login({
				username: _trim(_get(data, 'username')),
				password: _trim(_get(data, 'password'))
			});

			await this.acceptedTerms();

			if (!this.hasErrors) {
				await this.$router.push('/search');
			}
		},

		/**
		 * When pressing submit on login form, need ability to verify if user accepted terms, then display message
		 */
		handleChange() {
			this.deniedTerms = !this.terms;
		},

		/**
		 * User has accepted the terms
		 * @return {Promise} Log user accepted terms
		 */
		async acceptedTerms() {
			if (!this.hasErrors) {
				await this.createNote({
					'text': 'User accepted Terms of Service',
					'reason_created': 'Tiered Access User Login',
					// Tag name is important
					'tags': ['terms-accepted']
				});
			}
		}
	}
};
</script>

<style lang="less" scoped>
@import (reference) "theme";

.information {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	width: 100%;
	max-width: @mainMaxWidth;
	background: @white;

	h3 {
		font-size: @f30px;
	}

	> div {
		flex: 0 1 auto;
		max-width: calc(@mainMaxWidth / 2);
		padding: calc(@p20px * 3) @p20px @p20px;
	}

	p {
		max-width: calc(@sectionMaxWidth / 2);
		margin-top: @p20px;
		color: #4e4d4d;
	}
}

.flat-checkbox label {
	font-weight: normal;
	line-height: @p20px;
	text-transform: none;
}

.terms-link {
	text-indent: 0.2em;
}

.forgot {
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
